/* 
@ 컬러 시스템 정의 영역
@ root 영역 변수 선언하여 tailwind 또는 기타 css 파일에 할당하여 재사용 가능
*/

:root {
  --font-sans: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
    'Helvetica', sans-serif;
  --color-background-white-f3: #f3f3f3;
  --color-background-white-f2: #f2f2f2;
  --color-background-white-f5: #f5f5f5;
  --color-background-white-f2f5: #f2f2f5;
  --color-border-white-ddd: #ddd;
  --color-brand-red: #e63740;
  --color-main-red: #f02d37;
  --color-main-purple: #9472f1;
  --color-blue: #4ca1f9;
  --color-gray-eee: #eee;
  --color-gray-bbb: #bbb;
  --color-gray-aaa: #aaa;
  --color-gray-666: #666;
  --color-white-ddd: #ddd;
  --color-black-333: #333;
  --color-black-666: #666;
  --color-purple: #ae5cee;
  --max-width-400: 400px;

  /* dark mode */
  --color-black-1e1f21: #1e1f21; /* body bg color */
  --color-black-2c2d30: #2c2d30; /* header & footer && newSeries bg color */
  --primary-white: #fff; /* title && text color */
  --primary-red: #f02d37;
  --secondary-red: #ff323a;
  --tertiary-red: #ff464d;
  --color-red-ff4242: #ff4242;
  --color-gray-bbb: #bbb;
  --color-gray-777: #777;
  --color-gray-242528: #242528;
  --color-gray-252528: #252528;
  --color-gray-29292C: #29292c;
  --color-gray-3f4145: #3f4145; /* left && right btnBg */
  --color-gray-3f3f3f: #3f3f3f;
  --color-gray-35363A: #35363a;
  --color-gray-4d4d4d: #4d4d4d;
  --color-gray-575960: #575960;
  --color-gray-656565: #656565;
  --color-gray-bbbcbc: #bbbcbc;
  --color-gray-a6a6a6: #a6a6a6;

  --color-comicitme-edit-bg: #00000099;
  --color-lib-checkbox-purchased: #1e1f2100;

  --color-button-background-purple: linear-gradient(to left, #5d87f1, #9365fe),
    linear-gradient(to right, #7462c8, #7462c8);
  --color-button-background-red: linear-gradient(
      to left,
      --color-main-red,
      #ff5d5dw
    ),
    linear-gradient(to right, var(--color-main-red), var(--color-main-red));

  --color-main-button-background-red: linear-gradient(
      to left,
      #ff5454,
      var(--color-main-red)
    ),
    linear-gradient(to right, var(--color-main-red), var(--color-main-red));
}
