/*
 * Animation CSS
 */

@layer components {
  .slideEffect {
    /*
    * (23.03.26) 겹침현상 때문에 검색창 width 변경
    */
    width: 260px;
    animation: slideEffect 0.2s cubic-bezier(0, 0, 0.58, 1);
    -webkit-animation: slideEffect 0.2s cubic-bezier(0, 0, 0.58, 1);
    -moz-animation: slideEffect 0.2s cubic-bezier(0, 0, 0.58, 1);
    -o-animation: slideEffect 0.2s cubic-bezier(0, 0, 0.58, 1);
  }

  @keyframes slideEffect {
    0% {
      width: 0;
    }
    100% {
    }
  }

  @-webkit-keyframes slideEffect {
    0% {
      width: 0;
    }
    100% {
    }
  }

  @-moz-keyframes slideEffect {
    0% {
      width: 0;
    }
    100% {
    }
  }

  @-o-keyframes slideEffect {
    0% {
      width: 0;
    }
    100% {
    }
  }

  .animation_slidein {
    animation: slidein 0.2s;
    -moz-animation: slidein 0.2s;
    /* Firefox */
    -webkit-animation: slidein 0.2s;
    /* Safari and Chrome */
    -o-animation: slidein 0.2s;
    /* Opera */
    animation-fill-mode: forwards;
  }

  @keyframes slidein {
    from {
      opacity: 0;
      transform: translateX(5%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-moz-keyframes slidein {
    from {
      opacity: 0;
      transform: translateX(5%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes slidein {
    from {
      opacity: 0;
      transform: translateX(5%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-o-keyframes slidein {
    from {
      opacity: 0;
      transform: translateX(5%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .animation_fadein {
    animation: fadein 0.2s;
    -moz-animation: fadein 0.2s;
    /* Firefox */
    -webkit-animation: fadein 0.2s;
    /* Safari and Chrome */
    -o-animation: fadein 0.2s;
    /* Opera */
    animation-fill-mode: forwards;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .basedOnNovel {
    animation: text-scroll 4s linear infinite;
  }

  @keyframes text-scroll {
    0% {
      transform: translate3d(22%, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes change {
    0% {
      transform: translateY(0);
    }
    27% {
      transform: translateY(0);
    }
    33% {
      transform: translateY(-17px);
    }
    60% {
      transform: translateY(-17px);
    }
    67% {
      transform: translateY(-34px);
    }

    93% {
      transform: translateY(-34px);
    }

    100% {
      transform: translateY(-51px);
    }
  }

  /* S갤러리쪽 관련 애니메이션 */
  .currentImg {
    animation: fadeIn 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }

  /* blink : 헤더쪽 성인/비성인 버튼 관련 태그 */
  .blink {
    animation: blink 0.8s ease-in-out infinite alternate;
  }

  .blink_non {
    animation: none;
  }

  @keyframes blink {
    0% {
      border: solid 1px #26be9862;
      filter: drop-shadow(0px 0px 0px rgba(38, 190, 151, 0.45));
    }
    100% {
      border: solid 1px #26be97;
      filter: drop-shadow(0px 0px 3px rgba(38, 190, 151, 0.45));
    }
  }

  /* .blink:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 3px;
    vertical-align: middle;
    border-radius: 50px;
    background: #26be97;
    animation: blink 0.8s ease-in-out infinite alternate;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */

  /* .blink_non:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 3px;
    vertical-align: middle;
    border-radius: 50px;
    background: #cecece;
  } */

  .warning_msg {
    animation: shake 0.6s linear;
    -webkit-animation: shake 0.6s linear;
  }
  @-webkit-keyframes shake {
    8%,
    41% {
      -webkit-transform: translateX(-10px);
    }
    25%,
    58% {
      -webkit-transform: translateX(10px);
    }
    75% {
      -webkit-transform: translateX(-5px);
    }
    92% {
      -webkit-transform: translateX(5px);
    }
    0%,
    100% {
      -webkit-transform: translateX(0);
    }
  }
  /* Toast */
  .toast_ep {
    @apply bg-black bg-opacity-80 rounded-[50px] inline-block fixed z-[1] w-[60vw] py-[15px] px-[20px] text-center max-w-[310px] shadow-toast right-0 left-0 bottom-[4.5rem] m-auto;
    animation-name: toastBounceUp;
    -webkit-animation-name: toastBounceUp;
    animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;
  }

  @keyframes toastBounceUp {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
      opacity: 0;
      transform: translate3d(0, 3000px, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0);
    }
    75% {
      transform: translate3d(0, -10px, 0);
    }
    90% {
      transform: translate3d(0, 5px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .isHalloween {
    background: linear-gradient(to left, #ff9715 40%, #6b39ff 50%, #b835ff 60%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease;
    animation: text-clip 2s linear infinite;
  }

  @keyframes text-clip {
    to {
      background-position: -200% center;
    }
  }

  /*
  * spin 로딩에 사용
 */
  .animate-spin {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
