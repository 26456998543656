@import './reset.css';
@import './common.css';
@import './colors.css';
@import './animation.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* reCaptcha display none 처리 */
.grecaptcha-badge {
  visibility: hidden;
}
