/* 리본뱃지  */
@layer components {
  /* 메인배너 Pagination CSS */
  /*TODO: swiper에서 bullet DOM 생성으로 인하여 해당영역에 선언 해결책 찾을필요 있음 */
  #swiperPagination {
    & .swiper-pagination-bullet {
      @apply bg-[#333] mo:bg-primary-bg opacity-20 dark:bg-white w-[7px] h-[7px] rounded-[50px] dark:opacity-30 m-0;
    }

    & .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply bg-[#f14750] w-[14px] opacity-100;

      @screen mo {
        @apply w-[12px];
      }
    }
  }

  /* 메인배너 Pagination CSS */

  .ribon-con {
    @apply absolute bottom-[8px] left-[-4px] flex flex-col justify-start items-start z-[3];

    & span + span {
      margin-top: -0.15rem;
    }

    @screen mo {
      image-rendering: auto;
    }
  }

  .filter-imageBlur {
    filter: brightness(0.5) blur(5px);
  }
}

@layer base {
  /*  Modal Lock  */
  .modal {
    @apply overflow-hidden w-full h-full;
  }
  /*  Modal Lock  */

  /* newSeires 양쪽 버튼 공통 */
  .nav_btn {
    @apply w-[50px] h-[50px] rounded-[50%] bg-white absolute flex justify-center items-center cursor-pointer opacity-0 group-hover:opacity-100 shadow-mainBnrArrowBtnNext transition-all ease-[cubic-bezier(0.42,0,0.58,1)] duration-200;

    & > img {
      @apply w-[26px];
    }
  }
  /* newSeires 양쪽 버튼 공통 */

  /* thumbnailLoading */
  .emptyLoading {
    @apply bg-emptyLoading bg-[#f8f8f8] dark:bg-emptyLoadingDark bg-no-repeat bg-center bg-[length:85%];
    &.bgAuto {
      /* 회차리스트 메인 썸네일 영역용 */
      @apply bg-[length:auto];
    }
    &.bgContain {
      @apply bg-[length:contain];
    }
  }
  /* thumbnailLoading */

  /* 이벤트용 (크리스마스, 할로윈 등등) */
  .event_logo {
  }

  .event_gnb {
    @screen tablet {
      border-image: url('/images/common/event/christmas/img_bg_active_mo.svg') 4;
      background-image: none !important;
    }
  }

  .event_home {
    @apply bg-[url("/images/common/event/christmas/img_main_bg.png")] bg-no-repeat bg-[center_66px];
  }

  .event_etc {
    @apply bg-[url("/images/common/event/christmas/img_main_bg.png")] bg-no-repeat bg-[center_0px];
  }

  .event_main_daily {
    @apply relative bg-[url("/images/common/event/christmas/img_daily_trees.png")] bg-[length:202px_89px] bg-no-repeat bg-[right_6px]  mo:bg-[length:0px_0px] tablet:pt-[20px] mo:pt-0 tablet:mt-0;
  }

  .event_daily {
    @apply relative py-[30px] bg-[url("/images/common/event/christmas/img_daily_trees.png")] bg-[length:202px_89px] bg-no-repeat bg-[right_6px]  mo:bg-[length:0px_0px] tablet:pt-[20px] mo:pt-0 tablet:mt-0;
  }

  /* 이벤트용 (크리스마스, 할로윈 등등) */
}
